import React, { useState } from 'react';
import { Grid, TextField, Box, Button, Typography } from '@material-ui/core';
import axios from 'axios';
import validator from 'validator';

export const Feedback = () =>
{
    const [body, setBody] = useState("");
    const [from, setFrom] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const disableSubmit = loading || success || body.length <= 3 || (from.length > 0 && !validator.isEmail(from));

    const onSubmit = () => 
    {
        setError(false);
        setSuccess(false);
        setLoading(true);

        const data = {
            body,
            ...from && { from }
        }

        axios.post('feedback/send', data)
            .then((response) =>
            {
                setSuccess(true)
            })
            .catch((error) =>
            {
                setError(true)
            })
            .finally(() =>
            {
                setLoading(false);
            });
    }

    return (
        <Box width={600} maxWidth="100%">
            <TextField
                label="Feedback"
                placeholder="Feel free to say or ask for anything"
                multiline
                required
                variant="filled"
                fullWidth
                rows={10}
                onChange={(e) => setBody(e.target.value)}
            />
            <br />
            <br />
            <TextField
                label="Email"
                placeholder="Your email (optional)"
                variant="filled"
                fullWidth
                onChange={(e) => setFrom(e.target.value)}
            />
            <br />
            <br />
            <Button
                variant="contained"
                fullWidth
                onClick={onSubmit}
                disabled={disableSubmit}
            >
                { loading && "Sending feedback..." || success && "Thank you very much for your feedback!" || "Submit"}
            </Button>

            {error &&
                <>
                    <br />
                    <br />
                    <Typography>
                        Our feedback service seems to be down.
                        Please try again later, or email me at <a href="mailto:retro@arkuni.com">retro@arkuni.com</a>. Sorry for the inconvenience!
                    </Typography>
                </>
            }
        </Box>
    );
}