import { Header } from 'components';
import React, { FC } from 'react';
import { useLayoutStyles } from './Layout.styles';

export const Layout: FC = (props) =>
{
    const classes = useLayoutStyles();

    return (
        <>
            <div className={classes.layout}>
                <Header />
                {props.children}
            </div>
        </>
    );
}
