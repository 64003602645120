export enum IconType {
    Icon = 1,
    Emoji = 2,
    Flag = 3,
    Stickmen = 4,
}

export const types = [IconType.Icon, IconType.Emoji, IconType.Flag, IconType.Stickmen];

export const typeStrings = {
    [IconType.Icon]: "Icon",
    [IconType.Emoji]: "Emoji",
    [IconType.Flag]: "Flag",
    [IconType.Stickmen]: "Stickmen",
};