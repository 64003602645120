import { Shop } from "models/Shop";

export const shops: Shop[] = [
    {
        title: "itch.io",
        url: "https://arkuni.itch.io/bit-blob",
    },
    {
        title: "Unity",
        url: "https://assetstore.unity.com/packages/slug/163027",
    },
];