import React, { FC } from 'react';
import { useIconItemStyles } from './IconItem.styles';
import { Icon, IconFile } from 'models';
import { Typography, ButtonBase, Button, Box, ButtonProps } from '@material-ui/core';
import { colorStrings, sizeStrings, IconFileVariant, variantStrings } from 'enums';

export interface IconItemProps
{
    icon: Icon;
    file: IconFile;
}

export const IconItem: FC<IconItemProps> = (props) =>
{
    const { icon, file } = props;
    const classes = useIconItemStyles();

    let path = `/Icons/Icons--${colorStrings[file.color]}--${sizeStrings[file.size]}`;
    if (file.variant !== IconFileVariant.Standard)
    {
        path += `--${variantStrings[file.variant]}`;
    }
    path += `/${icon.fileName}`;

    return (
        <div className={classes.iconItem}>
            <Box className={classes.iconItem__container} >
                <Button target="_blank" download href={path} className={classes.iconItem__button}>
                    <div>
                        <div className={classes.iconItem__imageBg} style={{ backgroundImage: `url("${path}")` }} />
                        <div>
                            <Typography color="textPrimary" className={classes.iconItem__name} gutterBottom>
                                {icon.prettyName}
                            </Typography>
                            <Typography color="textPrimary" className={classes.iconItem__descriptor}>
                                {icon.descriptor}
                            </Typography>
                        </div>
                    </div>
                </Button>
            </Box>
        </div>
    );
}
