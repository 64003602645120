import { icons } from 'data';
import { IconFileColor, IconFileSize, IconFileVariant } from "enums";
import { IconFileItem } from "models";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

export interface SearchParameters
{
    query: string;
    variant?: IconFileVariant;
    color?: IconFileColor;
    size?: IconFileSize;
    page: number;
}

export interface SearchResult
{
    items: IconFileItem[];
    hasPrevious: boolean;
    hasNext: boolean;
}

export type SearchPush = (func: (parameters: SearchParameters) => Partial<SearchParameters>) => void;

export type SearchOverrideParameters = (parameters: SearchParameters) => Partial<SearchParameters>;

export type SearchCreateUrl = (parameters: SearchParameters) => string;

export const useSearch = () => 
{
    const history = useHistory();
    const location = useLocation();
    const params = queryString.parse(location.search);

    const query = params.query as string | undefined || "";
    const variant = typeof params.variant === "string" ? parseInt(params.variant) as IconFileVariant || undefined : IconFileVariant.Standard;
    const color = typeof params.color === "string" ? parseInt(params.color) as IconFileColor || undefined : IconFileColor.White;
    const size = typeof params.size === "string" ? parseInt(params.size) as IconFileSize || undefined : IconFileSize.p07;
    const page = typeof params.page === "string" && parseInt(params.page) || 0;

    const parameters: SearchParameters = {
        query,
        variant,
        color,
        size,
        page,
    };

    const itemsPerPage = 128;
    const itemIndexFrom = page * itemsPerPage;
    const itemIndexTo = page * itemsPerPage + itemsPerPage;

    // console.log("[" + icons.map(icon => `"${icon.fileName}"`).join(", ") + "]");

    const createNewParameters = (func: SearchOverrideParameters) =>
    {
        const partialParameters = func(parameters);
        const newParameters = { ...parameters, ...partialParameters };

        return newParameters;
    }

    const createUrl: SearchCreateUrl = (newParameters: SearchParameters) => 
    {
        let url = `/?query=${newParameters.query}`;
        url += `&variant=${newParameters.variant}`;
        url += `&color=${newParameters.color}`;
        url += `&size=${newParameters.size}`;
        url += `&page=${newParameters.page}`;

        if (newParameters.page !== parameters.page && window)
        {
            window.scrollTo(0, 0);
        }

        return url;
    }

    const push: SearchPush = (func) => 
    {
        const newParameters = createNewParameters(func);
        const url = createUrl(newParameters);

        history.push(url);

        /*
        ReactGA.event({
            category: 'Search',
            action: 'Search for query',
            label: newParameters.query,
        });

        ReactGA.event({
            category: 'Search',
            action: 'Search for variant',
            value: newParameters.variant,
        });

        ReactGA.event({
            category: 'Search',
            action: 'Search for color',
            value: newParameters.color,
        });

        ReactGA.event({
            category: 'Search',
            action: 'Search for size',
            value: newParameters.size,
        });
        */
    }

    const queryIcons = query.length === 0 && icons || icons.map((icon) =>
    {
        const queryModified = query.toLowerCase().trim();
        const queryWords = queryModified.split(/[ ]{1,}/);
        const nameModified = icon.prettyName.toLowerCase();
        const nameWords = nameModified.split(/[ ]{1,}/);
        const descriptorModified = icon.descriptor?.toLowerCase();
        const descriptorWords = descriptorModified?.split(/[ ]{2,}/);
        const keywordMatch = queryWords.every(queryWord => (
            nameWords.some(nameWord => nameWord.includes(queryWord)) ||
            descriptorWords && descriptorWords.some(descriptorWord => descriptorWord.includes(queryWord))
        ));
        const tagMatch = icon.tags.find(tag => tag.score > 70000 && queryWords.every(queryKeyword => tag.value === (queryKeyword)));

        const result = {
            icon,
            score: -1,
        }

        if (keywordMatch)
        {
            result.score = 1000000;
        }

        if (tagMatch)
        {
            result.score = tagMatch.score
        }

        return result;
    })
        .filter((icon) =>
        {
            return icon.score >= 0
        })
        .sort((a, b) =>
        {
            return b.score - a.score;
        })
        .map((icon) => 
        {
            return icon.icon;
        });

    const validItems = queryIcons
        .flatMap((icon) => icon.files
            .filter((iconFile, index) =>
            {
                if (variant !== undefined && variant !== iconFile.variant)
                    return false;

                if (color !== undefined && color !== iconFile.color)
                    return false;

                if (size !== undefined && size !== iconFile.size)
                    return false;

                return true;
            })
            .map<IconFileItem>((iconFile) =>
                ({
                    file: iconFile,
                    icon
                })
            )
        );

    const items = validItems
        .filter((item, index) =>
        {
            if (index < itemIndexFrom)
                return false;

            if (index >= itemIndexTo)
                return false;

            return true;
        });


    const hasPrevious = itemIndexFrom > 0;
    const hasNext = itemIndexTo < validItems.length - 1;
    const result: SearchResult = {
        items,
        hasPrevious,
        hasNext,
    }

    return {
        parameters,
        push,
        createUrl,
        hash: location.hash,
        result,
    };
}