import iconsJson from './icons.json';
import iconTagsJson from './icons.generatedTags.json';
import { Icon } from 'models/Icon';
import { IconTag } from 'models/IconTag';

const _iconTags: { [id: string]: IconTag[]; } = JSON.parse(JSON.stringify(iconTagsJson));

const _icons: Icon[] = (JSON.parse(JSON.stringify(iconsJson)) as Icon[]).map(icon => ({
    ...icon,
    tags: _iconTags[icon.fileName] || [],
}));

export const icons = _icons;