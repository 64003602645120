import { Card, CardContent, Container } from '@material-ui/core';
import React, { FC } from 'react';
import { Browser } from './Browser';
import { SearchPage } from 'components';

export const Routes: FC = (props) =>
{

    return (
        <Container>
            <SearchPage />
        </Container>
    );
}
