import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from 'assets/icons/Search.png';
import React, { FC, useEffect, useState } from 'react';
import { useBrowserStyles } from './Browser.styles';

export interface BrowserQueryProps 
{
    value: string;
    onChange?: (value: string) => void;
}

export const BrowserQuery: FC<BrowserQueryProps> = (props) =>
{
    const classes = useBrowserStyles();
    const [query, setQuery] = useState(props.value);

    useEffect(() => 
    {
        const timeout = setTimeout(() => 
        {
            if (props.onChange)
                props.onChange(query);

        }, 500);

        return () =>
        {
            clearTimeout(timeout);
        };
    }, [query]);

    return (
        <TextField
            fullWidth
            value={query}
            placeholder="Search"
            variant="filled"
            onChange={(e) => setQuery(e.target.value)}
            inputProps={{
                className: classes.browser__searchInput,
            }}
        />
    );
}
