import { makeStyles, Theme } from "@material-ui/core";

export const useIconItemStyles = makeStyles((theme: Theme) => ({
    iconItem: {
        width: 148,
        height: 148,
        textAlign: "center",
    },
    iconItem__container: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    iconItem__button: {
        width: "100%",
        height: "100%",
        padding: 0,
        textTransform: "initial",
    },
    iconItem__name: {
        fontSize: 12
    },
    iconItem__descriptor: {
        fontSize: 10,
        opacity: 0.5,
    },
    iconItem__imageBg: {
        width: 64,
        height: 64,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 48,
        margin: "auto",
        marginBottom: theme.spacing(1),
    },
    iconItem__image: {
        width: 48,
        height: 48,
    },
}))