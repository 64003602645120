export enum IconFileSize
{
    p07 = 1,
    p08 = 2,
    p12 = 3
}

export const sizes = [IconFileSize.p07, IconFileSize.p08, IconFileSize.p12]

export const sizeNumber = {
    [IconFileSize.p07]: 7,
    [IconFileSize.p08]: 8,
    [IconFileSize.p12]: 12,
};

export const sizeStrings = {
    [IconFileSize.p07]: "07px",
    [IconFileSize.p08]: "08px",
    [IconFileSize.p12]: "12px",
};

export const sizePrettyStrings = {
    [IconFileSize.p07]: "7px",
    [IconFileSize.p08]: "8px",
    [IconFileSize.p12]: "12px",
};