import { createMuiTheme, PaletteColorOptions, darken, lighten } from "@material-ui/core";
import shape from "@material-ui/core/styles/shape";
import { Shadows } from "@material-ui/core/styles/shadows";

const primaryColorMain = "#425790";
const primaryColor: PaletteColorOptions = {
    main: primaryColorMain,
    dark: darken(primaryColorMain, 0.5),
    light: lighten(primaryColorMain, 0.5),
    contrastText: "#fff",
};

const shadows: Shadows = [
    "none",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
    "0px 4px 0px 0px rgba(0,0,0,0.2)",
];

const newTheme = createMuiTheme({
    shadows,
    palette: {
        type: 'dark',
        primary: primaryColor,
        background: {
            paper: primaryColor.main,
        }
    },
    transitions: {
        create: () => 'none',
    },
    typography: {
        allVariants: {
            fontFamily: "PixelFont !important"
        },
    },
    shape: {
        borderRadius: 0
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiInputLabel: {
            shrink: true
        },
        MuiMenu: {
            transitionDuration: 0,
        }
    },
    overrides: {
        MuiDivider: {
            root:{
                height: 4,
            },
        },
        MuiMenu: {
            list: {

            },
            paper: {
                transition: "none",
                // border: "4px solid #333333",
                // boxSizing: "border-box",
            }
        },
        MuiMenuItem: {
            root: {
                transition: "none"
            }
        },
        MuiSelect: {
            root: {
                padding: 16,
                fontFamily: "PixelFont",
            },
            filled: {
                boxShadow: shadows[1]
            }
        },
        MuiTextField: {
            root: {
                fontFamily: "PixelFont",
                boxShadow: shadows[1]
            },
        },
        MuiFilledInput: {
            root: {
                fontFamily: "PixelFont",
                transition: "none",
            },
            underline: {
                "&:after": {
                    borderBottomWidth: 4
                },
                "&:before": {
                    borderBottomWidth: 0
                },
                "&:hover:before": {
                    borderBottomWidth: 0
                }
            }
        },
        MuiPopover: {
            root: {
                backgroundColor: "rgba(0, 0, 0, 0.65)",
            }
        },
        MuiInput: {
        },
        MuiInputBase: {
        },
        MuiButton: {
            root: {
                transition: "none",
            },
            contained: {
                backgroundColor: "rgba(255, 255, 255, 0.09)",
                color: "#fff",
                
                "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.13)",
                }
            },
        }
    }
});

export const theme = newTheme;