import { makeStyles, Theme } from "@material-ui/core";

export const useLayoutStyles = makeStyles((theme: Theme) => ({
    layout: {
        backgroundColor: theme.palette.primary.dark,
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        minHeight: "100vh",
        boxSizing: "border-box",
    }
}))