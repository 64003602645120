import { Card, CardContent } from '@material-ui/core';
import React, { FC } from 'react';
import { Browser, Feedback } from 'components';

export const SearchPage: FC = (props) =>
{

    return (
        <>
            <Card>
                <CardContent>
                    <Browser />
                </CardContent>
            </Card>
        </>
    );
}
