import { makeStyles, Theme } from "@material-ui/core";

export const useBrowserStyles = makeStyles((theme: Theme) => ({
    browser: {
        
    },
    browser__searchInput: {
        fontFamily: theme.typography.body1.fontFamily,
        padding: theme.spacing(2)
    },
    browser__searchContainer: {
        marginBottom: theme.spacing(4)
    },
    browser__searchPagination: {
        marginTop: theme.spacing(4),
        display: "flex",
        justifyContent: "center",
        margin: -theme.spacing(2),

        "& > *": {
            margin: theme.spacing(2),
        }
    }
}))