import { Button, Hidden, Box, Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useHeaderStyles } from './Header.styles';
import { Feedback } from './Feedback';
import BrandIcon from 'assets/icons/Dog.png';
import BuyIcon from 'assets/icons/Cart.png';
import FeedbackIcon from 'assets/icons/Mail.png';
import { shops } from 'data/Shops';

export const Header = () =>
{
    const classes = useHeaderStyles();
    const [buyAnchor, setBuyAnchor] = React.useState<HTMLElement>();
    const [feedbackAnchor, setFeedbackAnchor] = React.useState<HTMLElement>();

    const onClickBuy = (event: React.MouseEvent<HTMLElement>) =>
    {
        setBuyAnchor(event.currentTarget);
    };

    const onCloseBuy = (event: React.MouseEvent<HTMLElement>) =>
    {
        setBuyAnchor(undefined);
    }

    const onClickBuyOption = (url: string) => (event: React.MouseEvent<HTMLElement>) =>
    {
        setBuyAnchor(undefined);
        window.open(url, '_blank');
    }

    const onClickFeedback = (event: React.MouseEvent<HTMLElement>) =>
    {
        setFeedbackAnchor(event.currentTarget);
    };

    const onCloseFeedback = (event: React.MouseEvent<HTMLElement>) =>
    {
        setFeedbackAnchor(undefined);
    }

    return (
        <>
            <Toolbar />
            <AppBar position="fixed">
                <Toolbar>
                    <Box display="flex">
                        <img src={BrandIcon} width={32} height={32} alt="Logo" />
                        <Box width={16} />
                        <Typography variant="h6" noWrap>
                            Bit Blob - Pixel Icons
                        </Typography>
                    </Box>
                    <Box flexGrow={1} />
                    {/*
                    <Hidden smDown>
                        <Button onClick={onClickFeedback} color="inherit" endIcon={<img width={16} height={16} src={FeedbackIcon} alt="" />}>
                            Feedback
                        </Button>
                        <Box flexBasis={16} />
                        <Button onClick={onClickBuy} color="inherit" endIcon={<img width={16} height={16} src={BuyIcon} alt="" />}>
                            Buy
                        </Button>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton onClick={onClickFeedback} color="inherit">
                            <img width={16} height={16} src={FeedbackIcon} alt="" />
                        </IconButton>
                        <Box flexBasis={16} />
                        <IconButton onClick={onClickBuy} color="inherit">
                            <img width={16} height={16} src={BuyIcon} alt="" />
                        </IconButton>
                    </Hidden>
                     */}
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={feedbackAnchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                keepMounted
                getContentAnchorEl={null}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!feedbackAnchor}
                onClose={onCloseFeedback}
                MenuListProps={{
                    disablePadding: true,
                }}
            >
                <Box p={1}>
                    <Feedback />
                </Box>
            </Menu>
            <Menu
                anchorEl={buyAnchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                keepMounted
                getContentAnchorEl={null}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!buyAnchor}
                onClose={onCloseBuy}
                MenuListProps={{
                    disablePadding: true,
                }}
            >
                {shops.map((shop) => (
                    <MenuItem key={shop.title} onClick={onClickBuyOption(shop.url)}>{shop.title}</MenuItem>
                ))}
            </Menu>
        </>
    );
}