export enum IconFileColor {
    White = 1,
    Black = 2
}

export const colors = [IconFileColor.White, IconFileColor.Black];

export const colorStrings = {
    [IconFileColor.White]: "White",
    [IconFileColor.Black]: "Black",
};