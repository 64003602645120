export enum IconFileVariant {
    Standard = 1,
    Outline = 2,
    Extrude = 3,
    Shadow = 4,
    Dotted = 5
}

export const variants = [IconFileVariant.Standard, IconFileVariant.Outline, IconFileVariant.Shadow, IconFileVariant.Extrude, IconFileVariant.Dotted];

export const variantStrings = {
    [IconFileVariant.Standard]: "Standard",
    [IconFileVariant.Outline]: "Outline",
    [IconFileVariant.Extrude]: "Extrude",
    [IconFileVariant.Shadow]: "Shadow",
    [IconFileVariant.Dotted]: "Dotted",
};