import { Grid } from "@material-ui/core";
import { IconItem } from "components";
import { IconFileItem } from "models";
import React, { FC, Fragment, useState } from "react";


export interface IconGridProps 
{
    items: IconFileItem[];
}

export const IconGrid: FC<IconGridProps> = (props) => 
{
    const { items } = props;
    return (
        <Grid container spacing={0}>
            {items.length > 0 && items.map(item => (
                <Fragment key={item.file.key}>
                    <Grid item>
                        <IconItem icon={item.icon} file={item.file} />
                    </Grid>
                </Fragment>
            ))}
        </Grid>
    );
}