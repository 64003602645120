import { Box, Button, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { colors, colorStrings, IconFileColor, IconFileSize, IconFileVariant, sizePrettyStrings, sizes, variants, variantStrings } from "enums";
import { useSearch } from 'hooks/useSearch';
import React, { ChangeEvent, FC, FormEvent } from 'react';
import { useBrowserStyles } from './Browser.styles';
import { IconGrid } from './IconGrid';
import { BrowserQuery } from "components";

export const Browser: FC = () =>
{
    const classes = useBrowserStyles();

    const search = useSearch();
    const { query, variant, color, size, page } = search.parameters;

    const onChangeQuery = (value: string) =>
    {
        search.push(() => ({
            query: value,
            page: 0
        }));
    }

    const onChangeVariant = (event: ChangeEvent<{ value: unknown }>) =>
    {
        const value = event.target.value as IconFileVariant;
        search.push(() => ({
            variant: value,
            page: 0
        }));
    }

    const onChangeColor = (event: ChangeEvent<{ value: unknown }>) =>
    {
        const value = event.target.value as IconFileColor;
        search.push(() => ({
            color: value,
            page: 0
        }));
    }

    const onChangeSize = (event: ChangeEvent<{ value: unknown }>) =>
    {
        const value = event.target.value as IconFileSize;
        search.push(() => ({
            size: value,
            page: 0
        }));
    }

    const onChangePage = (value: number) => () =>
    {
        search.push(() => ({
            page: page + value
        }));
    }

    const onSubmit = (e: FormEvent<HTMLFormElement>) => 
    {
        e.preventDefault();
    }

    return (
        <form onSubmit={onSubmit}>
            <div className={classes.browser}>
                <div className={classes.browser__searchContainer}>
                    <BrowserQuery
                        value={query}
                        onChange={onChangeQuery}
                    />
                    <Box mb={2} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm>
                            <Select variant="filled" value={variant || ""} onChange={onChangeVariant} displayEmpty fullWidth >
                                {variants.map((variant) => (
                                    <MenuItem key={variant} value={variant}>{variantStrings[variant]}</MenuItem>
                                ))}
                                <MenuItem value="">Any variant</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm>
                            <Select variant="filled" value={color || ""} onChange={onChangeColor} displayEmpty fullWidth>
                                {colors.map((color) => (
                                    <MenuItem key={color} value={color}>{colorStrings[color]}</MenuItem>
                                ))}
                                <MenuItem value="">Any color</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm>
                            <Select variant="filled" value={size || ""} onChange={onChangeSize} displayEmpty fullWidth>
                                {sizes.map((size) => (
                                    <MenuItem key={size} value={size}>{sizePrettyStrings[size]}</MenuItem>
                                ))}
                                <MenuItem value="">Any size</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </div>
                {search.result.items.length > 0 &&
                    <IconGrid items={search.result.items} />
                }
                {search.result.items.length === 0 &&
                    <Box width="100%" paddingY={4}>
                        <Typography align="center">
                            No icons matches your search query "{query}".
                        </Typography>
                    </Box>
                }
                {(search.result.hasPrevious || search.result.hasNext) &&
                    <div className={classes.browser__searchPagination}>
                        <Button
                            disabled={!search.result.hasPrevious}
                            onClick={onChangePage(-1)}
                        >
                            Previous
                        </Button>
                        <Button
                            disabled={!search.result.hasNext}
                            onClick={onChangePage(1)}
                        >
                            Next
                        </Button>
                    </div>
                }
            </div>

        </form>
    );
}
